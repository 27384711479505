const __request = require(`./__request/__request_contentType_json`);

// 行程订单申请列表
const recordCount = (data) => {
let pParameter = {
    method: 'post',
    // urlSuffix: '/flight/domestic/buyer/manage/itineraryOrder/params',
    urlSuffix:'/buyer/dom/manage/itinerary/detail',
    data:data
  }

  return __request(pParameter)
}
export default recordCount
