const __request = require(`../__request/__request_contentType_json`);

// 国内申请行程单
export default (data) => {
    let pParameter = {
        method: 'post',
        urlSuffix: 'buyer/dom/manage/itinerary/add',
        data: data
    }

    return __request(pParameter)
}

