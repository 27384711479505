import Q from 'q';

import itineraryLogistics from "@/lib/data-service/flight/flight_domestic_buyer_manage_order_itinerary_logistics";
import itineraryParams from "@/lib/data-service/flight/flight_domestic_buyer_manage_order_itinerary_params";
import itineraryApply from "@/lib/data-service/flight/flight_domestic_buyer_manage_order_itinerary_apply";
import itineraryPay from "@/lib/data-service/flight/flight_domestic_buyer_pay";
import getInlandAreaChildListByParentIdResponse
    from "@/lib/data-service/default/web_common_area_getInlandAreaChildListByParentIdResponse";
import payment from "@/lib/data-service/flight/newFligthApi/payment_params";
import PayMenthod from "@/lib/common-service/getPayModule";

import ItineraryTotal from "@/lib/data-service/flight/newFligthApi/buyer_dom_manage_itinerary_total";
import itineraryUpdate from "@/lib/data-service/flight/newFligthApi/buyer_dom_manage_itinerary_update";

import saveDetail from "@/lib/data-service/flight/newFligthApi/buyer_dom_manage_itinerary_save_detail";

export default {
    name: "hotelOrder",
    data() {
        var checkPhone = (rule, value, callback) => {
            const phoneReg = /^1[3|4|5|7|8][0-9]{9}$/;
            if (!value) {
                return callback(new Error("电话号码不能为空"));
            }
            if (!Number.isInteger(+value)) {
                callback(new Error("请输入数字值"));
            } else {
                if (phoneReg.test(value)) {
                    callback();
                } else {
                    callback(new Error("电话号码格式不正确"));
                }
            }
        };
        return {
            formData: {
                currentPage: 1,
                pageSize: 10,
                psgNoList: "",
                itineraryType: 1,
                mailingID: 1
            },
            dialogVisible: false,
            totalFeeList: {},
            loading: true,
            orderStatu: 0,
            costShow: false,
            affirmStatu: false,
            expressageCost: 0,
            orderNo: "",
            logisticsList: [],
            inputInfo: {},
            detailList: {
                totalFee: 0
            },
            mailInfo: null,
            siteList: {},
            rules: {
                addressee: {required: true, message: "请输入收件人", trigger: "blur"},
                areaCitys: {required: true, message: "请选择城市", trigger: "blur"},
                street: {required: true, message: "请输入街道信息", trigger: "blur"},
                phone: {validator: checkPhone, trigger: "blur", required: true}
            },
            detailInfo: {},
            addStatu: 0
        };
    },
    components: {PayMenthod},
    async created() {
        // let psgNo = this.$route.query.psgNo;

        // let psgNoAll = this.$route.query.psgNoAll;
        // let ticketNumberList = this.$route.query.ticketNumberList;
        // this.orderNo = this.$route.query.orderNo;
        // psgNo
        //   ? ((this.formData.psgNoList = [psgNo]),
        //     (this.formData.ticketNumList = ticketNumberList.split(",")))
        //   : "";
        // psgNoAll
        //   ? ((this.formData.psgNoList = psgNoAll.split(",")),
        //     (this.formData.ticketNumList = ticketNumberList.split(",")))
        //   : "";
        let dataInfo = this.$route.query.dataInfo;
        dataInfo ? (this.formData.passengerVOList = JSON.parse(dataInfo)) : "";
        let res = await this.getInfo();
        this.getLogistics();
        this._getInlandArea("100000", res => {
            this.$set(this.siteList, "area", res);
        });
        this.$nextTick(() => {
            this.payInit();
        });
    },
    watch: {},
    computed: {},
    methods: {
        payInit() {
            let orderInfo;
            let __this = this;
            orderInfo = payment({type: 14});
            orderInfo.then(res => {
                this.$refs.payComponent.init({
                    get_params() {
                        const p = {
                            companyId: res.companyId,
                            clubId: res.clubId,
                            orderNo: __this.detailInfo.itineraryNo,
                            businessContent: res.businessContent,
                            subject: "国内机票行程单支付",
                            orderAmount: Number(__this.totalFeeList.total),
                            // pay_remaining_seconds:1200
                            count_down: {
                                enable: false
                            },
                        };
                        return Q.resolve(p);
                    },

                    //支付操作前的事件处理方法，常用于验证是否能够进行支付操作
                    async before_pay_event_handler() {
                        let statu;
                        statu = await __this.submit();
                        // .then(res => {
                        //   if (res.code == "000000") {
                        //     __this.addStatu = 1;
                        //     return true;
                        //   }
                        // })
                        // .catch(() => {
                        //   return false;
                        // });
                        if (statu.code === "000000") {
                            __this.addStatu = 1;
                            return Promise.resolve("success");
                        } else {
                            return Promise.reject("error");
                        }
                    },

                    //支付成功事件处理方法
                    pay_successful_event_handler() {
                        // __this.submit().then(res => {
                        //   if (res.code == "000000") {
                        //     __this.addStatu = 1;
                        //   }
                        itineraryUpdate({
                            itineraryNo: __this.detailInfo.itineraryNo,
                            status: __this.addStatu
                        }).then(res => {
                            __this.$router.go(-1);
                        });
                        // __this.submit().then(res => {
                        //   __this.$router.go(-1);
                        // });
                        __this.$message({type: "success", message: "支付成功！"});
                    },

                    //支付失败事件处理方法
                    pay_failed_event_handler() {
                        __this.$message({
                            type: "warning",
                            message: "支付失败，请重新支付！"
                        });
                    },

                });
            });
        },
        cost() {
            let data = {
                itineraryType: this.formData.itineraryType,
                mailingId: this.expressageCost.id,
                psgCount: this.detailList.list.length
            };
            ItineraryTotal(data).then(res => {
                console.log(res);
                this.totalFeeList = res.result;
                this.payInit();
            });
        },
        getInfo() {
            let data = Object.assign({}, this.formData);
            let obj = {};
            if (data.passengerVOList instanceof Array) {
                obj.ticketNumList = data.passengerVOList.map(item => {
                    return item.ticketNumber;
                });
                obj.psgNoList = data.passengerVOList.map(item => {
                    return item.psgNo;
                });
            } else {
                obj.ticketNumList = [data.passengerVOList.ticketNumber];
                obj.psgNoList = [data.passengerVOList.psgNo];
            }
            return itineraryParams(obj).then(res => {
                this.loading = false;
                this.detailInfo = res;
                this.detailList.list = res.result;
                this.$forceUpdate();
            });
        },
        selectExpressage(val) {
            let list = this.logisticsList.filter(item => {
                return item.id == val;
            });
            this.expressageCost = list[0];
            console.log(list);
            this.mailInfo = list;
            this.cost();
        },
        getLogistics() {
            itineraryLogistics().then(res => {
                this.logisticsList = res.result;
                this.expressageCost = res.result[0];
                this.formData.mailingID = this.expressageCost.id;
                this.cost();
            });
        },
        _getInlandArea(id, callback) {
            getInlandAreaChildListByParentIdResponse({id: id}).then(res => {
                callback(res.areaTreeResult);
            });
        },
        _getArea(val) {
            this._getInlandArea(val[0], res => {
                this.formData.areaProvinces ? (this.formData.areaProvinces = "") : "";
                this.formData.areaCitys ? (this.formData.areaCitys = "") : "";
                this.$set(this.siteList, "province", res);
            });
        },
        _getProvince(val) {
            this._getInlandArea(val[0], res => {
                this.formData.areaCitys ? (this.formData.areaCitys = "") : "";
                this.$nextTick(() => {
                    this.$set(this.siteList, "city", res);
                });
            });
        },
        submit() {
            let data = this.formData;
            data.distributionType = "邮寄";
            data.areaCitys
                ? (data.address =
                data.areaRegions[1] + data.areaProvinces[1] + data.areaCitys[1])
                : "";
            // console.log(this.formData)
            let form = {},
                status;
            this.$refs["userForm"].validate(valid => {
                if (valid) {
                    // if (!data.mailingID)
                    //   return this.$message({
                    //     type: "warning",
                    //     message: "请选择邮寄方式"
                    //   });
                    // if (!this.affirmStatu)
                    //   return this.$message({
                    //     type: "warning",
                    //     message: "请勾选服务细则"
                    //   });
                    form = {
                        address: data.address,
                        addressee: data.addressee,
                        amount: 0,
                        distributionType: data.distributionType,
                        mailingName: this.mailInfo
                            ? this.mailInfo[0].type
                            : this.logisticsList[0].type,
                        payAmount: this.totalFeeList.total,
                        itineraryNo: this.detailInfo.itineraryNo,
                        phone: data.phone,
                        postalCode: data.postalCode,
                        psgNoList: data.psgNoList,
                        itineraryType: data.itineraryType,
                        street: data.street,
                        passengerVOList:
                            data.passengerVOList instanceof Array
                                ? data.passengerVOList
                                : [data.passengerVOList]
                        // street: 1,
                        // printFee: Number(__this.totalFeeList.total)
                    };
                    this.inputInfo = form;
                    status = true;
                    // itineraryApply(data).then(res => {
                    //   let list = {
                    //     orderNo: res.itineraryOrderNo,
                    //     paymentChannel: "YEEPAY",
                    //     paymentType: 13,
                    //     totalFee: res.amount
                    //   };
                    //   this.payment(list);
                    // });
                } else {
                    status = false;
                }
            });
            if (status) {
                return saveDetail(form);
            } else {
                return Promise.reject("请完善信息！");
            }
        }
        // payment(val) {
        //   itineraryPay(val).then(res => {
        //     window.open(res.htmlStr);
        //   });
        // }
    }
};
