const __request = require(`../__request/__request_contentType_json`);

// 国内行程单费用
export default (data) => {
  let pParameter = {
    method: 'post',
    urlSuffix:'/buyer/dom/manage/itinerary/total',
    data: data
  }

  return __request(pParameter)
}

